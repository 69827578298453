<template>
  <v-card :min-height="minHeight">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `Монетизація ${getMonetaryTypeTranslate(header.monetary_type)} (файл із оплатами)` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-3 pb-3">
      <v-subheader class="subtitle-1 pl-1 success--text pb-2" style="height: 24px">
        {{ `Створено: ${formatDate(header.create_date, 'DD.MM.YYYY HH:mm:ss')}, файл: ${header.filename}` }}
      </v-subheader>
      <v-row>
        <v-col cols="4">
          <date-component v-model="header.month" label="Місяць" disabled/>
        </v-col>
        <v-col cols="4">
          <date-component v-model="header.period" label="Період файлу" disabled/>
        </v-col>
        <v-col cols="4">
          <v-select
              :items="services" v-model="header.service_id" disabled
              filled
              hide-details color="grey"
              label="Послуга"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pt-0 pb-3">
      <v-switch v-model="show_error" class="py-0 my-0" hide-details
                :label="show_error ? 'Відображати лише помилки' : 'Відображати усе'"
                color="secondary"
                @change="setPageSplitter"/>
    </v-card-text>
    <v-card-text class="pb-2">
      <div class="table-without-lines" style="overflow: auto; padding-bottom: 2px">
        <div class="table-header">
          <div class="table-row">
            <div class="table-col" style="flex: 0 0 60px">
              №
            </div>
            <div class="table-col" style="flex: 0 0 90px">
              Номер УПСЗН
            </div>
            <div class="table-col" style="flex: 0 0 125px">
              № облікового запису
            </div>
            <div class="table-col" style="flex: 0 0 200px">
              ПІБ
            </div>
            <div class="table-col" style="flex: 0 0 120px">
              Особовий рах. (файлу)
            </div>
            <div class="table-col" style="flex: 0 0 120px">
              Особовий рах.
            </div>
            <div class="table-col" style="flex: 1; min-width: 240px">
              Адреса
            </div>
            <div class="table-col" style="flex: 0 0 90px">
              Сальдо
            </div>
            <div class="table-col" style="flex: 0 0 90px">
              Оплачено
            </div>
            <div class="table-col" style="flex: 0 0 180px">
              Текст помилки
            </div>
          </div>
        </div>
        <div class="table-body">
          <div class="table-row"
               v-for="(item, index) in filtered_items" :key="`item-${item.row_num}-${index}`">
            <div class="table-col" style="padding-left: 10px !important; flex: 0 0 60px">
              {{ item.row_idx }}
            </div>
            <div class="table-col" style="flex: 0 0 90px">
              {{ item.social_org_code }}
            </div>
            <div class="table-col" style="flex: 0 0 125px">
              {{ item.bank_person_id }}
            </div>
            <div class="table-col" style="flex: 0 0 200px; text-transform: capitalize">
              {{ item.full_name.toLowerCase() }}
            </div>
            <div class="table-col" style="flex: 0 0 120px">
              {{ item.person_id }}
            </div>
            <div class="table-col" style="flex: 0 0 120px">
                <AUC_FlatSearch
                        :row_num="item.row_num"
                        :flat_id="item.flat_id"
                        :text_value="item.person_id_search"
                        :text_value_key="'person_id'"
                        :class="{'error-input': !item.flat_id}"
                        thin
                        @selectChange="flatChange"
                />
            </div>
            <div class="table-col" style="flex: 1; min-width: 240px">
              {{ item.address }}
            </div>
            <div class="table-col" style="flex: 0 0 90px">
              <input type="text"
                     :value="item.debt"
                     @input="item.debt = +$event.target.value || 0"
                     disabled
                     v-decimal>
            </div>
            <div class="table-col" style="flex: 0 0 90px">
              <input type="text"
                     :value="item.pay"
                     @input="item.pay = +$event.target.value || 0"
                     disabled
                     v-decimal>
            </div>
            <div class="table-col" style="flex: 0 0 180px">
              {{ item.error_text }}
            </div>
          </div>
        </div>
      </div>
      <div class="pay-table-pagination d-flex justify-start" v-if="items.length">
        <div class="pa-3 d-flex align-center justify-start">
          <v-btn text depressed icon class="mr-1"
                 @click.stop="pageStartOrEnd(true)"
                 :disabled="items_per_page > items.length || page === 1"
          >
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
          <v-btn text depressed icon
                 @click.stop="changePage(true)"
                 :disabled="page === 1"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="mx-2">
            <v-chip>
              {{ `Сторінка: ${page} із ${pages}` }}
            </v-chip>
          </div>
          <v-menu top :close-on-click="true" :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" class="mr-2">
                {{ `${items_per_page} на стор.` }}
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                  v-for="(item, index) in items_per_page_list"
                  :key="index"
                  @click="setItemsPerPage(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text depressed icon class="mr-1"
                 @click.stop="changePage(false)"
                 :disabled="items_per_page > items.length || page === pages"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn text depressed icon
                 @click.stop="pageStartOrEnd(false)"
                 :disabled="page === pages"
          >
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Закрити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import {QUESTION_SHOW} from "@/store/actions/question";
import monetaryAPI from "@/utils/axios/monetary";
import {ALERT_SHOW} from "@/store/actions/alert";
import {formatDate} from "@/filters";
import {getMonetaryTypeTranslate} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'monetary_pay_modal_delete'

export default {
  name: "HWP_Modal_MonetaryPay",
  mixins: [ModalComponentMixin],
  props: ['minHeight'],
  components: {
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
  },
  data() {
    return {
      header: {},
      items: [],
      pages: 0,
      items_per_page: 15,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
      show_error: false
    }
  },
  methods: {
    formatDate,
    getMonetaryTypeTranslate,
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документу № ${this.itemId}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    flatChange(payload) {
      const current_row = this.items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.address = payload.address || ''
        current_row.person_id_search = payload.person_id
        current_row.flat_id = payload.value
        current_row.edited = true
        current_row.error = !current_row.flat_id
      }
    },
    crud_item() {
      const payload = {
        header_id: this.itemId,
        rows: this.items.filter(item => item.edited)
      }

      monetaryAPI.save_pay_monetary(payload)
        .then(response => response.data)
        .then(() => {
          this.$store.commit(ALERT_SHOW, { text: 'Документ успішно збережений', color: 'success' })
          this.$emit('crud', Object.assign({}, {method: 'fetch', isDebt: false}))
          this.closeModal()
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      let length = 0
      if (this.show_error) {
        length = (this.items.filter(item => item.error) || []).length
      } else {
        length = this.items.length
      }
      this.pages = Math.ceil(length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id

              monetaryAPI.get_pay_monetary(this.itemId)
                  .then(response => response.data)
                  .then(data => {
                    this.pages = 0
                    this.page = 0

                    this.header = data.header
                    const rows = data.rows

                    this.items = rows.map((item, idx) => {
                      return Object.assign(item, {row_num: idx + 1, row_idx: idx + 1, error: !item.flat_id})
                    })

                    this.setPageSplitter()
                  })
                  .catch(err => {
                    const error = err.response.data.detail;
                    this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                  })
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  monetaryAPI.remove_pay_monetary(this.itemId)
                    .then(response => response.data)
                    .then(() => {
                      this.$emit('crud', Object.assign({}, {method: 'fetch', isDebt: false}))
                      this.closeModal()
                      this.$store.commit(ALERT_SHOW, {text: 'Документ успішно вилучений', color: 'success'})
                    })
                    .catch(err => {
                      const error = err.response.data.detail;
                      this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    })
                }
              }
            }
          }
      )
    },

    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
    }),
    filtered_items() {
      if (this.show_error) {
        let items = this.items.filter(item => item.error)
        return items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      } else {
        return this.items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-header {
  opacity: .8;

  .table-col:nth-child(1) {
    //border-left: 4px solid transparent;
  }
}

.table-body {
  .table-row {
    height: 25px !important;
    //border-left: 4px solid #4caf50d1;
    margin-bottom: 1px;
    background-color: rgba(243, 243, 243, 0.5);

    &:hover {
      transition: 90ms all ease-in;
      background-color: rgba(199, 199, 199, 0.3);
    }

    .table-col {
      overflow: hidden;
      //white-space: pre;
      align-self: center;
      text-overflow: ellipsis;
      font-size: .82rem !important;
      background-color: transparent !important;
      word-wrap: break-word;
      white-space: nowrap;
    }
  }
}

input {
  width: 90%;
  display: block;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, .4);
  padding: 2px 4px 2px 0;
  transition: all .2s ease-in-out;

  &:focus {
    border-color: var(--v-success-base);
    font-weight: 500;
  }

  //&[disabled] {
  //  border-bottom: 2px dotted rgba(0, 0, 0, .4);
  //  background-color: #f1f1f1;
  //}
}
.table-col {
  :deep(.error-input) {
    input {
      border-bottom: 1px solid #d76662 !important;
    }
  }
}

</style>